<template>
    <div>
        <CRow>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <strong> Search user by criteria </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardHeader>
                        <CInput v-c-tooltip.hover.click="`Query might be ${queries} `"
                                @input="userLookup()" v-model="search_criteria" placeholder="query> value">
                            <template #prepend>
                                <CButton @click="userLookup()" size="sm" color="primary">
                                    <CIcon name="cil-magnifying-glass"/>
                                    Search
                                </CButton>
                            </template>
                        </CInput>
                    </CCardHeader>
                    <CListGroupItem @click="getLogs(user)" v-for="user in founded_users" href="#" class="flex-column align-items-start">
                        <div v-c-tooltip.hover.click="'Click to get logs for this user.'"
                             class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{user.usr_firstname}} {{user.usr_lastname}} </h5>
                        </div>
                        <ul>

                            <li>
                                Email Address: {{user.usr_email}}
                            </li>
                            <li>
                                Phone Number: {{user.usr_phonenumber}}
                            </li>
                        </ul>
                    </CListGroupItem>
                </CCard>
            </CCol>
            <CCol sm="8">
                <CDataTable
                        hover
                        striped
                        sorter
                        columnFilter
                        itemsPerPageSelect
                        responsive
                        :items="items"
                        :fields="fields"
                        :items-per-page="50"
                        clickable-rows
                        :active-page="activePage"
                        @row-clicked="rowClicked"
                        :pagination="{ doubleArrows: true, align: 'center'}"
                        @page-change="pageChange"

                >
                </CDataTable>


            </CCol>

        </CRow>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        name: 'UserLogs',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('wlans')
            })
        },
        data() {
            return {

                founded_users: [],
                items:[],
                fields:[
                    {key: 'log_id', value: 'Log Id'},
                    {key: 'log_service', value: 'Service'},
                    {key: 'log_level', value: 'Log Level'},
                    {key: 'log_timestamp', value: 'Created'},
                    {key: 'log_comment', value: 'Log Comment'}
                ],
                queries: ['usr_id', 'usr_firstname', 'usr_lastname', 'usr_email',
                    'usr_phonenumber'],
                search_criteria: ""
            }
        },
        methods: {
            getLogs(user){

                console.log(user)

                axios.get(this.$baseURL + '/users/db/logs?usr_id=' + user.usr_id)
                .then( (res) => {
                    this.items = res.data;
                }).catch(error => {
                    this.$toast.error("Failed to get logs for selected user." + error)
                })
            },
            userLookup() {

                if (!this.search_criteria.includes('>')) {
                    return
                }
                var query = this.search_criteria.split('>')[0];
                var value = "";

                if (this.queries.includes(query)) {
                    value = this.search_criteria.split('> ')[1];
                }
                if (!value) {
                    return
                }

                axios.get(this.$baseURL + '/users/db/search?param=' + query + "&value=" + value)
                    .then((res) => {
                        this.founded_users = res.data;
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )
            }
        },
        mounted() {
            this.validateSession();
        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>